/* You can add global styles to this file, and also import other style files */
@import "~@ctrl/ngx-emoji-mart/picker";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import '~primeicons/primeicons.css';

:root {
  --dark-blue-grey: #21464f;
  --dark-grey-blue: #22444e;
  --focus-theme-green: #1bc175;
  --focus-sky-blue: #23b7e5;
  --focus-red: #f05050;
  --foucs-mustard: #feb92e;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
body {
  font-family: manrope;
}

body {
  overflow-x: unset;
}

.mat-ink-bar {
  height: 1px !important;
}

.container-fluid {
  min-height: 100vh;
}

.toast-title {
  font-weight: bold;
}

.moment-picker {
  z-index: 5000 !important;
}

.form-control {
  border-color: #d1d1d1;
  border-radius: 2px;
}

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #f47e7d;
}

.form-control.profile-border:focus {
  border-color: #48b8e6;
}

.form-control.virtual-care:focus {
  border-color: #48b8e6;
}

.form-control.virtual-care::-webkit-input-placeholder {
  color: #313131;
  font-size: x-small;
  font-weight: 100;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.form-horizontal .control-label.text-left {
  text-align: left;
}

.hr-m-reduce {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.thumb-xxl {
  width: 120px;
  display: inline-block;
}

.wrapper-xs {
  padding: 5px;
}

.wrapper-sm {
  padding: 10px;
}

.wrapper {
  padding: 15px;
}

.wrapper-md {
  padding: 20px;
}

.wrapper-lg {
  padding: 30px;
}

.wrapper-xl {
  padding: 50px;
}

.padder-lg {
  padding-right: 30px;
  padding-left: 30px;
}

.padder-md {
  padding-right: 20px;
  padding-left: 20px;
}

.padder {
  padding-right: 15px;
  padding-left: 15px;
}

.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.no-padder {
  padding: 0 !important;
}

.pull-in {
  margin-right: -15px;
  margin-left: -15px;
}

.pull-out {
  margin: -10px -15px;
}

.b {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.b-a {
  border: 1px solid #dee5e7;
}

.b-t {
  border-top: 1px solid #dee5e7;
}

.b-r {
  border-right: 1px solid #dee5e7;
}

.b-b {
  border-bottom: 1px solid #dee5e7;
}

.b-l {
  border-left: 1px solid #dee5e7;
}

.b-light {
  border-color: #edf1f2;
}

.b-dark {
  border-color: #3a3f51;
}

.b-black {
  border-color: #3a3f51;
}

.b-primary {
  border-color: #7266ba;
}

.b-success {
  border-color: #27c24c;
}

.b-info {
  border-color: var(--focus-sky-blue);
}

.b-warning {
  border-color: #fad733;
}

.b-danger {
  border-color: var(--focus-red);
}

.b-white {
  border-color: #ffffff;
}

.b-dashed {
  border-style: dashed !important;
}

.b-l-light {
  border-left-color: #edf1f2;
}

.b-l-dark {
  border-left-color: #3a3f51;
}

.b-l-black {
  border-left-color: #3a3f51;
}

.b-l-primary {
  border-left-color: #7266ba;
}

.b-l-success {
  border-left-color: #27c24c;
}

.b-l-info {
  border-left-color: var(--focus-sky-blue);
}

.b-l-warning {
  border-left-color: #fad733;
}

.b-l-danger {
  border-left-color: var(--focus-red);
}

.b-l-white {
  border-left-color: #ffffff;
}

.b-l-2x {
  border-left-width: 2px;
}

.b-l-3x {
  border-left-width: 3px;
}

.b-l-4x {
  border-left-width: 4px;
}

.b-l-5x {
  border-left-width: 5px;
}

.b-2x {
  border-width: 2px;
}

.b-3x {
  border-width: 3px;
}

.b-4x {
  border-width: 4px;
}

.b-5x {
  border-width: 5px;
}

.r {
  border-radius: 2px 2px 2px 2px;
}

.r-2x {
  border-radius: 4px;
}

.r-3x {
  border-radius: 6px;
}

.r-l {
  border-radius: 2px 0 0 2px;
}

.r-r {
  border-radius: 0 2px 2px 0;
}

.r-t {
  border-radius: 2px 2px 0 0;
}

.r-b {
  border-radius: 0 0 2px 2px;
}

.m-xxs {
  margin: 2px 4px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0 !important;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-xxl {
  margin-left: 50px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-l-n-xxl {
  margin-left: -50px;
}

.m-t-none {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-xxl {
  margin-top: 50px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-t-n-xxl {
  margin-top: -50px;
}

.m-r-none {
  margin-right: 0 !important;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-xxl {
  margin-right: 50px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-r-n-xxl {
  margin-right: -50px;
}

.m-b-none {
  margin-bottom: 0 !important;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-xxl {
  margin-bottom: 50px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.m-b-n-xxl {
  margin-bottom: -50px;
}

.fw-600 {
  font-weight: 600;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  position: relative;
  display: block;
  white-space: nowrap;
  border-radius: 500px;
}

.avatar img {
  width: 100%;
  border-radius: 500px;
}

.avatar i {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-style: solid;
  border-width: 2px;
  border-radius: 100%;
}

.avatar i.right {
  right: 0;
  left: auto;
}

.avatar i.bottom {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

.avatar i.left {
  top: auto;
  bottom: 0;
}

.avatar i.on {
  background-color: #27c24c;
}

.avatar i.off {
  background-color: #98a6ad;
}

.avatar i.busy {
  background-color: var(--focus-red);
}

.avatar i.away {
  background-color: #fad733;
}

.avatar.thumb-md i {
  width: 12px;
  height: 12px;
  margin: 3px;
}

.avatar.thumb-sm i {
  margin: 1px;
}

.avatar.thumb-xs i {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.col-sm-12 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.btn-slim {
  padding: 2px 10px 3px 8px !important;
  border-radius: 2px !important;
}

.btn-slim i {
  padding: 7px 8px 7px 8px !important;
  margin: -3px 4px -3px -10px !important;
}

.btn {
  line-height: inherit !important;
  box-shadow: none !important;
}

.btn-icon.btn-sm {
  width: 27px !important;
  height: 27px !important;
}

.btn-icon.btn-sm i {
  line-height: 28px !important;
  font-size: 14px;
}

.mat-dialog-container {
  padding: 0px !important;
}

/* Material Select Css */
.mat-form-field-wrapper {
  padding: 0 !important;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup {
  transform: unset !important;
}

.mat-select-panel-wrap {
  border-left: 0.5px solid #c5d1d4;
  border-right: 0.5px solid #c5d1d4;
  border-bottom: 0.5px solid #c5d1d4;
  transform: translate(5px, 40px);
  /* box-shadow:unset !important; */
}

.mat-autocomplete-panel {
  border-left: 0.5px solid #c5d1d4;
  border-right: 0.5px solid #c5d1d4;
  border-bottom: 0.5px solid #c5d1d4;
  box-shadow: unset !important;
}

.mat-form-field-underline,
.mat-form-field-subscript-wrapper {
  display: none !important;
}

.mat-form-field-flex {
  background: #ffffff !important;
  border: 0.5px solid #c5d1d4;
  color: #75787b !important;
  height: 38px;
  align-items: center !important;
  padding: 0px 10px !important;
  border-radius: 2px !important;
}

.mat-form-field-should-float.mat-focused .mat-form-field-flex {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.mat-form-field-infix {
  border-top: 0 !important;
  padding: 0 !important;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: unset !important;
}

.mat-form-field-label {
  top: unset !important;
  margin-top: unset !important;
}

.mat-select-panel {
  min-width: unset !important;
  border-radius: unset !important;
  box-shadow: none !important;
  max-width: unset !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: unset !important;
}

.mat-form-field.mat-focused .mat-form-field-label,
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #75787b;
}

.mat-option {
  /* background-color: white !important; */
  height: unset !important;
  min-height: 42px !important;
  line-height: unset !important;
}

.mat-ripple-element {
  background-color: transparent !important;
}

.mat-option.mat-active,
.mat-option:hover {
  background-color: #eeeeee !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white !important;
  background-color: #239be5 !important;
}

.form-control {
  box-shadow: none !important;
}

.mat-select-value-text {
  color: #75787b;
}

.header-tab .mat-tab-label-content {
  margin-top: 5px !important;
  color: #58666e !important;
}

.mat-tab-label {
  opacity: 1 !important;
  height: 49px !important;
  opacity: 1 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5bac53 !important;
}

.mat-tab-label {
  flex-grow: 1 !important;
}

.mat-tab-body-content {
  overflow: unset !important;
}

.popover .arrow::after,
.popover .arrow::before {
  border-style: unset !important;
}

mat-expansion-panel {
  margin: 0 !important;
  box-shadow: unset !important;
}

.call-main {
  border-radius: unset !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.segement-wrapper mat-expansion-panel:last-child span.mat-content {
  border-bottom: unset !important;
}

.sl-item popover-content .popover-body {
  white-space: pre-line !important;
}


emoji-picker {
  left: 68% !important;
  transform: none !important;
  top: 87% !important;
}

emoji-content {
  height: 295px !important;
}

.demoModal {
  margin-top: -350px;
}

.inbox-tabs {
  background-color: #d4ebda !important;
  color: white !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.setActiveInboxTab {
  background-color: #22464f !important;
  color: white !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.small-dropdown {
  line-height: unset !important;
  min-height: unset !important;
  height: 28px !important;
  font-size: 12px !important;
}

.small-dropdown div.mat-ripple>* {
  background-color: transparent !important;
}

.classic-dropdown {
  background-color: white !important;
  height: unset !important;
  min-height: 38px !important;
  line-height: unset !important;
  font-size: 14px !important;
}

.mat-autocomplete-panel-above .mat-autocomplete-panel {
  border-top: 0.5px solid #c5d1d4;
  border-bottom: none;
}

.popover {
  pointer-events: none !important;
}

.multiselect-dropdown .selected-item-container {
  padding: 2px !important;
  padding-right: 0px !important;
}

.focus-tinymce .mce-statusbar {
  display: none;
}

.focus-tinymce .mce-tinymce {
  border: 0.5px solid #c5d1d5 !important;
  border-radius: 2px;
  box-shadow: unset !important;
}

.focus-tinymce .mce-edit-area {
  border: none !important;
}

.toast-custom {
  width: 100% !important;
}

.pill-priority {
  border: none;
  text-align: center;
  border-radius: 4px;
  width: 58px;
  transition: background-color 0.3s;
  color: white;
  overflow: hidden;
  font-family: Manrope;
  font-weight: 500;
  font-size: 10px;
}

.pill-low {
  background-color: #1FBD75;
}

.pill-medium {
  background-color: #FEB92E;
}

.pill-high {
  background-color: #F05050;
}


.patient-feed-container {
  padding: 5px 25px 0px 5px;
  margin-top: -10px;
  margin-bottom: 5px;
  display: flex;
}

.text-container{
  width: 100%;
  height: 75px;
  padding: 25px 10px 10px 10px;
}

.text-container:hover{
  background: #21464F;
  color: #ffffff;
  cursor: pointer;
}

.patient-feed-container-active{
  background: #21464F;
  color: #ffffff;
  cursor: pointer;
}

.patient-feed-box {
  margin: -35px 10px 5px 45px;
}

.patient-feed-box-container {
  padding: 10px;
  margin-top: -10px;
  border-right: 1px solid #d1d1d1;
}


.patient-feed-message {
  background: #EFF3F4;
  padding: 20px;
  border-radius: 0px 15px 15px 15px;
}
.circle-down-feed {
  font-size: 19px;
  background: white;
  float: right;
  color: red;
  z-index: 11;
  margin:-50px -30px 0px 10px;
}
.circle-down-feed-green{
  font-size: 19px;
  background: white;
  float: right;
  color: rgb(0, 255, 8);
  z-index: 11;
  margin: -50px -30px 0px 10px;
}
.dashed-line-vertical {
  /* font-size: 19px;
  margin: -10px 354px;
  position: absolute;
  color: red;
  border-left: 4px dashed rgb(240 240 240 / 58%);
  min-height: 20vh; */
}
.nav-pills {
  border-right: 1px solid silver;
  padding-right: 15px;
}
.tab-content {
  padding-left: 15px;
}
.label-text{
  color: #75787B;
}
.button-left{
  float: right;
  margin: -52px 10px
}
.btn-create{
  padding: 8px !important;
  border-radius: 5px !important;
  background: #21464F !important;
  color: #fff !important;
}
.btn-create:hover{
  background: rgba(33, 70, 79, 0.78);
  color: #fff;
}


